import BlockHeader from '@component/BlockHeader';
import Formie from '@component/Formie';
import { BlockContainer } from '@components/Blocks';
import { createBlock } from '@lib/features/blocks/blocks';

const FormBlock = createBlock<'blocks_form_BlockType'>(({ heading, content, form }) => {
  return (
    <BlockContainer maxWidth="md">
      <BlockHeader
        {...{ heading, HeadingProps: { variant: 'h4' }, content, cx: { marginBottom: 'xs' } }}
      />
      <Formie form={form ?? undefined} />
    </BlockContainer>
  );
});

export default FormBlock;
